.overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    z-index: 10000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.modal-container{
    z-index: 10001;
    max-width: 500px!important;
    width: 100%;
    position: fixed;
    top:40%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    gap:1rem;
    background-color: #DDDDDD;
    flex-direction: column;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.5);
    padding:1rem;
    color: #222222;
}

.modal--content {
    display: flex;
    flex-direction: column;
    gap:0.75rem;
}

/* ---------------- HEADER ---------------- */
.modal--header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.header--button {
    font-size: 24px;
    color: #54565A;
}
.header--button:hover {
    color: #6e7075;
    font-weight: 900;
}

/* ---------------- CONTENT ---------------- */

:where(.ticket--data, .ticket--actors, .ticket--dates, .ticket--tags, .ticket--solution) {
    background: #F6F6F6;
    padding:0.5rem;
    border-radius: 0.5rem;
}

:where(.ticket--data, .ticket--actors, .ticket--dates, .ticket--tags, .ticket--solution) small {
    color: #898A8D;
    font-weight: bold;
}

.ticket--request span {
    color: #898A8D;
    font-style: italic;
}

/* - Tags - */

.ticket--tags .tags {
    display: flex;
    flex-direction: row;
    gap:0.5rem;
    margin-top: 0.25rem;
    flex-wrap: wrap;
}

.tag--data{
    padding: 0.25rem;
    background-color: rosybrown;
    border-radius: 0.25rem;
    color:#fff;
    font-weight: bold;
}

.tag--data span {
    font-weight: normal;
}

.tag--data[data-risk="alto"],
.tag--data[data-priority="alta"] {
    background-color: #d11a2a;
}
.tag--data[data-risk="medio"],
.tag--data[data-status="open"], 
.tag--data[data-priority="media"] {
    background-color: #cccc41;
}
.tag--data[data-risk="bajo"],
.tag--data[data-status="closed"], 
.tag--data[data-priority="baja"] {
    background-color: green;
}

.tag--data[data-status="pending"] {
    background-color: grey;
} 

.tag--data[data-origin="interno"] {
    background-color: #188577;
}
.tag--data[data-origin="discord"] {
    background-color: #7289DA;
}

/* - Ticket data - */

.ticket--data p {
    margin-top: 0.25rem;
}

.ticket--data .data--value {
    color: #54565A;
    font-weight: bold;
}

.ticket--data .data--value span {
    color: #222222;
    font-weight: normal;
}

/* -- FOOTER -- */
.modal--footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.modal--footer .btn-container {
    display: flex;
    gap: 0.5rem;
}

.btn-container button {
    padding: 0.5rem;
    border-radius: 0.5rem;
    box-shadow: none;
    transition: 0.25s;
    color: #fff;
}

.btn-container .close-btn {
    background-color: #d11a2a;
    border: 1px solid #d11a2a;
}

.btn-container .function-btn {
    background-color: #2a1dd1;
    border: 1px solid #2a1dd1;
}

.btn-container .close-btn:hover {
    background-color: #91121c;
    border: 1px solid #91121c;
}

.btn-container .function-btn:hover {
    background-color: #1b1397;
    border: 1px solid #1b1397;
}

/* -- Solution Form -- */
.form--solution {
    margin-top: 0.5rem;
}

.form-input--solution {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.25rem;
}

.form-input--solution select,
.form-input--solution textarea,
.form-input--solution input {
    padding: 0.5rem;
    background: #ddd;
    border: 1px solid #ddd;
    border-radius: 0.5rem;
    font-size: 14px;
    font-family: inherit;
}

.solution--button {
    background-color: #2a1dd1;
    border: 1px solid #2a1dd1;
    padding: 0.5rem;
    border-radius: 0.5rem;
    box-shadow: none;
    transition: 0.25s;
    color: #fff;
    width: fit-content;
}

/* New Ticket Form */
.new-ticket {
    max-width: 600px !important;
}
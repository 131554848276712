.top .dashboard-block--element {
    background-color: #ebebeb;
    padding: 1rem;
    border-radius: 1rem;
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 200px;
}

.bottom .dashboard-block {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 50%;
}

/* Utils */

.w-40 {
    width: 40%!important;
}

.w-30 {
    width: 30%!important;
}

.ml-1 {
    margin-left: 1rem;
}
.mr-1 {
    margin-right: 1rem;
}

.no-background {
    background: none!important;
}

.mb-1 {
    margin-bottom: 1.5rem;
}

/* Top Dashboard Blocks */

.top .n2 {
    width: calc(100% - 2rem)!important;
}

.top .dashboard-block {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.top .dashboard-block--title {
    background-color: #ebebeb;
    padding: 1rem;
    border-radius: 0.75rem;
    text-align: center;
    font-size: 1.25em;
    font-weight: 500;
}

.top .dashboard-block--element .numeralia {
    width: 60%;
}
.top .dashboard-block--element .pie-chart {
    width: 40%;
}
.top .dashboard-block--element .chart {
    width: 200px;
}

.top .dashboard-block--element .numeralia {
    text-align: center;
}

.top .dashboard-block--element .numeralia .number{
    font-size: 3.5em;
}
.top .dashboard-block--element .numeralia .label{
    font-size: 2em;
}

/* Bottom Dashboard Blocks */
.bottom .dashboard-block--element {
    background-color: #ebebeb;
    padding: 1rem;
    border-radius: 1rem;
    min-height: calc(303px - 2rem);
}

.bottom .dashboard-block--title {
    background-color: none;
    padding: 2rem 1rem 0 1rem;
    border-radius: 0.75rem;
    text-align: left;
    font-size: 1.75em;
    font-weight: 600;
}

.bottom .request {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
}
.bottom .request:last-child {
    margin-bottom: 0;
}

.request--count,
.request--text {
    background: #f6f6f6;
    padding: 0.75rem;
    border-radius: 0.5rem;
}

.request--count {
    font-size: 1.5em;
}
.request--text {
    font-size: .75em;
}
/* -- GENERAL --*/
footer{
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 0.75rem 0.75rem 0.75rem 0;
}

footer .indicators{
    display: flex;
    gap:1rem;
    align-items: center;
}

footer button {
    background-color: blue;
}
.absolute {
    position: absolute;
    top: 1rem;
    right: 1rem;
}

.absolute button {
    padding: 0.5rem;
    border-radius: 0.5rem;
    box-shadow: none;
    transition: 0.25s;
    color: #fff;
    display: flex;
    gap: 0.25rem;
    align-items: center;
    background-color: #2a1dd1;
    border: 1px solid #2a1dd1;
}

.request-actions{
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.request-actions button {
    padding: 0.25rem 0.5rem;
    background-color: black;
    color: #fff;
    border-radius: 0.33rem;
    box-shadow: none;
    transition: 0.25s;
    display: flex;
    gap: 0.25rem;
    align-items: center;
}

.request-actions .edit{
    background-color: #2a1dd1;
    border: 1px solid #2a1dd1; 
}
.request-actions .edit:hover{
    background-color: #1b1397;
    border: 1px solid #1b1397;
}
.request-actions .delete{
    background-color: #d11a2a;
    border: 1px solid #d11a2a; 
}
.request-actions .delete:hover{
    background-color: #91121c;
border: 1px solid #91121c;
}
.title {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.title p {
    margin-bottom: 0;
    background-color: #ccc;
    padding: 0.5rem;
    border-radius: 0.5rem;
}

.request-table {
    margin: 1rem 0;
    width: 100%;
    border-collapse: collapse;
}

td, th {
    border: 1px solid #ddd;
    padding: 8px;
}

th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #505050;
    color: white;
}

tr:nth-child(even){
    background-color: #f2f2f2;
}




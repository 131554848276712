/* -- GENERAL --*/
.absolute{
    position: absolute;
    top: 1rem;
    right: 1rem;
}

.absolute button{
    padding: 0.5rem;
    border-radius: 0.5rem;
    box-shadow: none;
    transition: 0.25s;
    color: #fff;
    display: flex;
    gap:0.25rem;
    align-items: center;
    background-color: #2a1dd1;
    border: 1px solid #2a1dd1;
}

.absolute button:hover {
    background-color: #1b1397;
    border: 1px solid #1b1397;
}

.ticket-container {
    margin:1rem 0;
    padding: 1rem;
    border: 1px solid #DDDDDD;
    border-radius: 1rem;
    color: #222222;
}
.tickets{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap:1rem;
}
  
.ticket-card {
    background-color: #DDDDDD;
    display: flex;
    flex-direction: column;
    gap:0.75rem;
    padding: 0.75rem;
    padding-left: 2.25rem;
    border-radius: 0.75rem;
    position: relative;
}

.ticket-card::before{
    content: '';
    position: absolute;
    height: 100%;
    width: 1.5rem;
    top:0;
    left:0;
    background-color: blue;
    border-radius: 0.75rem 0 0 0.75rem;
}

.ticket-card[data-priority="alta"]::before{
    background-color: #d11a2a;
}
.ticket-card[data-priority="media"]::before{
    background-color: #cccc41;
}
.ticket-card[data-priority="baja"]::before{
    background-color: green;
}

/* -- HEADER -- */

.ticket-card--header{
    display: flex;
    justify-content: space-between;
}

.ticket-card--header small, 
.ticket-card--footer small {
    color: #54565A;
    font-weight: bold;
}

.ticket-card--header small span,
.ticket-card--footer small span {
    color: #222222;
    font-weight: normal;
}

.ticket-card--header--text h3, .ticket-card--header--button {
    font-size: 24px;
}

.ticket-card--header-text h5 {
    font-size: 12px;
}

.ticket-card--header--button {
    color: #54565A;
}
.ticket-card--header--button:hover {
    color: #6e7075;
}

/* -- CONTENT -- */
.ticket--request{
    background: #F6F6F6;
    padding:0.5rem;
    border-radius: 0.5rem;
}
.ticket--request small {
    color: #898A8D;
    font-weight: bold;
}

.ticket--request p {
    margin-top: 0.25rem;
}

/* -- FOOTER -- */
/*.ticket-card--footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}*/
.function-buttons {
    display: flex;
    gap:0.5rem;
    justify-content: flex-end;
}

.function-buttons button{
    padding: 0.5rem;
    border-radius: 0.5rem;
    box-shadow: none;
    transition: 0.25s;
    color: #fff;
    font-size: 12px;
}

.delete {
    background-color: #d11a2a;
    border: 1px solid #d11a2a;
}

.function {
    background-color: #2a1dd1;
    border: 1px solid #2a1dd1;
}

.delete:hover {
    background-color: #91121c;
    border: 1px solid #91121c;
}

.function:hover {
    background-color: #1b1397;
    border: 1px solid #1b1397;
}
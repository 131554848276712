.overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    z-index: 10000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.modal-container{
    max-width: 500px;
    width: 100%;
    position: fixed;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    gap:1rem;
    background-color: #DDDDDD;
    flex-direction: column;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.5);
    padding:1rem;
    color: #222222;
}

/* ---------------- HEADER ---------------- */
.modal--header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

/* -- CONDITION -- */

.condition-checkbox {
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
    align-items: center;
}

/* ---------------- CONTENT ---------------- */

/* -- FORM MODAL -- */

form {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.select-group{
    display: flex;
    gap:0.75rem;
}

.form-input {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.25rem;
}

.form-input select,
.form-input textarea,
.form-input input {
    padding: 0.5rem;
    background: #f6f6f6;
    border: 1px solid #f6f6f6;
    border-radius: 0.5rem;
    font-size: 14px;
    font-family: inherit;
}

.input-group{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

/* -- FOOTER -- */
.modal--footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.modal--footer .btn-container {
    display: flex;
    gap: 0.5rem;
}

.btn-container button {
    padding: 0.5rem;
    border-radius: 0.5rem;
    box-shadow: none;
    transition: 0.25s;
    color: #fff;
}

.btn-container .close-btn {
    background-color: #d11a2a;
    border: 1px solid #d11a2a;
}

.btn-container .function-btn {
    background-color: #2a1dd1;
    border: 1px solid #2a1dd1;
}

.btn-container .close-btn:hover {
    background-color: #91121c;
    border: 1px solid #91121c;
}

.btn-container .function-btn:hover {
    background-color: #1b1397;
    border: 1px solid #1b1397;
}

/* -- FORM MODAL -- */



.Pagination{
    display: flex;
    list-style:none;
    justify-content: flex-end;
    margin-top: 1rem;
}
.PaginationItem {
  height: 30px;
  width: 30px;
  line-height:30px;
  color:#f5f5f5;
  background: #222222;
  text-align: center;
  cursor: pointer;
}

.PaginationIndex:hover, 
.PaginationNext.canGoNext:hover,
.PaginatioPrev.canGoPrev:hover{
    background: #4C82FF;
}

.PaginationNext, .PaginationPrev {
    background: #414757;
    line-height: calc(30px + 4px);
}

.PaginationPrev {
  border-radius:5px 0 0 5px;
}

.PaginationNext {
    border-radius:0 5px 5px 0px;
}

.PaginationItem.active{
    background: #4C82FF;
}
:root {
  --sidebar-background: #222222;
  --sidebar-accent: #4C82FF;
  --sidebar-gray: #414757;
}
*{
  margin:0;
  padding: 0;
  text-decoration: none;
  font-family: 'Roboto' sans-serif;
}

.container {
  display: flex;
}

main{
  width: 100%;
  padding: 1rem;
  height: calc(100vh - 2rem);
  overflow: scroll;
}
.sidebar{
  background:var(--sidebar-background);
  color:#fff;
  height: 100vh;
  width:300px;
  transition: all 0.5s;
}

.top_section{
  display: flex;
  align-items: center;
  padding: 20px 15px;
  gap:1rem;
}

.logo{
  font-size: 24px;
}

.bars{
  display: flex;
  font-size: 24px;
}

.submenu--header{
  display:flex;
  color:#e1e9fc;
  justify-content: space-between;
  align-items:center;
  padding: 20px;
  text-decoration:none;
}
.submenu--header:hover{

  background:#252831;
  border-left: 4px solid var(--sidebar-accent);
  cursor:pointer;

}

.submenu--link{
  background: var(--sidebar-gray);
  height:60px;
  padding-left:2em;
  display:flex;
  align-items:center;
  text-decoration: none;
  color: #f5f5f5;
  font-size:18px;
  gap:0.5rem;
}

.submenu--link:hover{
  background: var(--sidebar-accent);
  cursor:pointer;
  transition: all 0.5s;
}

.active{
  background: var(--sidebar-accent);
}

.icon, .link_text{
  font-size: 18px;
}

/*Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) { }

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { }

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { }